@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
        /* Start and end at the original position */
    }

    50% {
        transform: translateY(-40px);
        /* Move up by 40 pixels for a more pronounced bounce */
    }
}

.container {
    width: 100%;
    height: 93%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

@media (max-width: 1100px) {
    .responsive-container {
        flex-direction: column;
        height: auto;
        /* Adjust height for smaller screens */
    }
}

@media (min-width: 1101px) {
    .responsive-container {
        flex-direction: row;
    }
}

@media (max-width: 768px) {
    .container {
        padding: 10px;
        max-width: 90%;
    }
}



/* Modal backdrop */
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(228, 228, 228, 0.644);
    /* Light grey overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1040;
    /* Ensure modal is above other content */
}

/* Modal dialog */
.modal-dialog {
    max-width: 900px;
    height: 90vh;
    /* Adjusted to fit within viewport height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff9c4;
    /* Yellow background */
    border-radius: 8px;
    overflow: hidden;
    padding: 20px;
    z-index: 1050;
    /* Higher z-index to ensure above the backdrop */
}

/* Modal header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

/* Close button */
.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1060;
    /* Higher z-index to ensure clickability */
}

/* New tab link */
.new-tab-link {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 4px;
    margin-top: 10px;
    z-index: 1060;
    /* Higher z-index to ensure clickability */
}

/* Modal body */
.modal-body {
    flex: 1;
    overflow-y: auto;
    /* Ensure content is scrollable */
    margin-top: 10px;
}

/* Modal footer */
.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #ccc;
}

/* Close button in modal footer */
.btn-close {
    background-color: #6c757d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}


.bg-blue-dark {
    background-color: #0e416c !important;
}

.text-blue-dark {
    color: #0e416c !important;
}

.border-blue-dark {
    border-color: #0e416c !important;
}




@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

/* Microphone button styles */
.btn-microphone {
    background-color: #00897b;
    /* Teal color, similar to the one in the image */
    color: white;
    border-radius: 50%;
    /* Round shape */
    padding: 25px;
    border: none;
    outline: none;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    /* Stronger shadow for depth */
    position: relative;
    cursor: pointer;
    animation: pulse 2s infinite;
    /* Add pulsing effect */
}

.btn-microphone.listening {
    background-color: #00796b;
    /* Darker teal color when listening */
}

.btn-microphone:active {
    background-color: #00695c !important;
    /* Even darker teal */
    color: white;
    /* Maintain text/icon color */
    transform: scale(1.2);
    /* Slightly enlarge the button when pressed */
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.5);
    /* Stronger shadow when pressed */
}

.btn-microphone:hover {
    transform: scale(1.25);
    /* Slightly enlarge the button on hover */
    background-color: #00796b;
    /* Darker teal on hover */
    color: grey;
    /* Change icon color to grey on hover */
}

.btn-microphone:focus {
    background-color: #00897b;
    /* Retain primary teal color on focus */
    color: white;
    outline: none;
    /* Remove any default browser outline */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    /* Retain shadow */
}

.microphone-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-microphone:hover svg {
    color: #dde313;
    /* Change the microphone icon to a bright color on hover */
}


/* Chat box container with fixed height */
.chat-box {
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* height: 600px; */
    /* Fixed height for the chat box */
    overflow-y: auto;
    /* Scroll if content overflows */
    background-color: #d0e7f9;
    /* Light blue background */
    border-radius: 10px;
    /* Slightly round the edges */
    scroll-behavior: smooth;
    /* Smooth scrolling */
}

/* Message container */
.message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}

/* Bubbles for user messages (right-aligned) */
.message.user .bubble {
    background-color: #4CAF50;
    /* Same green as microphone button */
    color: #fff;
    align-self: flex-end;
    border-radius: 15px;
    /* Rounded edges */
    padding: 10px 15px;
    max-width: 60%;
    margin-left: auto;
    position: relative;
}

/* Bubbles for assistant messages (left-aligned) */
.message.assistant .bubble {
    background-color: #f5f5f5;
    /* Light grey bubble for assistant */
    color: #333;
    align-self: flex-start;
    border-radius: 15px;
    /* Rounded edges */
    padding: 10px 15px;
    max-width: 60%;
    margin-right: auto;
    position: relative;
}

/* Chat bubbles text styling */
.message .bubble {
    font-size: 1.3rem;
    /* Increase the font size to 1.5rem */
    line-height: 1.2;
    /* Adjust line height for readability */
}

/* Optional: Timestamp styling */
.timestamp {
    font-size: 12px;
    color: #888;
    margin-top: 5px;
    align-self: flex-start;
    padding-left: 10px;
}

/* Align text to the right for user messages */
.message.user {
    flex-direction: row-reverse;
}

/* Optional: Adjust font and text bubble styling for links */
.bubble a {
    color: #0366d6;
    /* Blue link color */
    text-decoration: underline;
}

/* Input group and button styling for larger text */
.input-group {
    font-size: 1.2rem;
    /* Larger font size for the input group */
    padding: 10px;
}

.input-group .form-control {
    font-size: 1.2rem;
    /* Larger text in input field */
    padding: 10px;
}

.input-group .btn-primary {
    font-size: 1.2rem;
    /* Larger text on the send button */
    padding: 10px 20px;
    /* Adjust padding for button */
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
}

.text-white {
    color: #fff !important;
}

.people-options {
    font-size: 1.5rem;
    /* Larger text for person buttons */
}


.people-options {
    height: 300px;
    background-color: #f0f0f0;
    /* Light grey background color */
    border-radius: 15px;
    /* Rounded corners */
    padding: 20px;
    /* Optional: Add padding for better spacing inside the box */
}