/* Flexbox container to adjust iframe and buttons layout */
.call-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Full viewport height */
}

/* Make the iframe take up 80% of the height */
.call-iframe {
    flex: 1;
    height: 80vh;
    /* Take 80% of the viewport height */
    width: 100%;
}

/* Container for the buttons */
.button-container {
    display: flex;
    justify-content: space-between;
    /* Distribute buttons evenly */
    align-items: center;
    height: 30vh;
    /* Reserve 30% of the viewport height */
    padding: 10px;
    background-color: #f1f1f1;
}

/* Button styles */
.button-container button {
    flex: 0 1 30%;
    /* Each button still takes up 30% of the container width */
    margin: 0 10px;
    padding: 10px;
    font-size: 1rem;
    /* Smaller font size */
    width: 120px;
    /* Set width to 120px for wider buttons */
    height: 60px;
    /* Keep the height 60px */
    border-radius: 10px;
    /* Slightly rounded corners */
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    /* Ensure the buttons remain the correct width */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .button-container {
        flex-direction: row;
        /* Ensure buttons stay in one row */
        height: auto;
        /* Adjust height dynamically for smaller screens */
    }

    .button-container button {
        flex: 0 1 30%;
        /* Each button still takes up 30% */
        margin: 0 5px;
        /* Smaller margin for mobile */
        width: 60px;
        /* Keep buttons square on smaller screens */
        height: 60px;
    }
}

/* Full-width for iframe in desktop view */
.call-iframe {
    height: 70vh;
    /* Set the iframe to take up 70% of the viewport height */
    width: 100%;
    margin-bottom: 10px;
    /* Space for buttons */
    border: none;
    /* Remove iframe border */
}