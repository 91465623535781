/* Flexbox container for gate, elevator, and barrier gate */
.gate-elevator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* Ensure it takes up the full viewport height */
    background-color: #f5f5f5;
    padding-top: 20px;
    /* Adds padding to avoid cutting off top content */
    box-sizing: border-box;
    overflow-x: hidden;
    /* Prevent horizontal overflow */
}

/* Title styling */
.title {
    font-size: 3em;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
}

/* Flexbox wrapper for the boxes */
.gate-elevator-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    gap: 60px;
    flex-wrap: wrap;
    /* Allows wrapping of elements on smaller screens */
}

/* Box styling (same size for all elements) */
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border: 3px solid #ccc;
    border-radius: 22.5px;
    background-color: #fff;
    box-shadow: 0px 7.5px 22.5px rgba(0, 0, 0, 0.1);
    width: 450px;
    height: 750px;
    transition: transform 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .box {
        width: 350px;
        height: 600px;
        /* Smaller size for medium screens */
    }
}

@media (max-width: 768px) {
    .gate-elevator-wrapper {
        flex-direction: column;
        /* Stack vertically on smaller screens */
        gap: 30px;
        /* Adjust gap between stacked items */
    }

    .box {
        width: 90%;
        /* Take up most of the screen width on smaller screens */
        height: auto;
        /* Allow height to adjust dynamically */
    }

    .title {
        font-size: 2.5em;
        /* Adjust title size for smaller screens */
    }

    .gate-elevator-container {
        padding-top: 50px;
        /* Adds more padding to avoid the top part being hidden */
    }
}

@media (max-width: 480px) {
    .box {
        width: 100%;
        padding: 15px;
        /* Reduce padding on smaller screens */
    }

    .title {
        font-size: 2em;
        /* Further adjust title size for very small screens */
    }

    .gate-elevator-container {
        padding-top: 70px;
        /* Add more padding to prevent cutting off top section */
    }
}

/* Regular button styling */
.toggle-button {
    margin-top: 20px;
    padding: 12px 25px;
    font-size: 18px;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

/* Button hover state */
.toggle-button:hover {
    background-color: #45a049;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
}

/* Alert button styling when the component is in "moving" or "open" state */
.alert-button {
    background-color: #ff4d4f;
    box-shadow: 0px 5px 15px rgba(255, 0, 0, 0.4);
}

.alert-button:hover {
    background-color: #ff7875;
    box-shadow: 0px 8px 25px rgba(255, 0, 0, 0.5);
}

/* Gate styling */
.gate {
    position: relative;
    width: 375px;
    height: 375px;
    perspective: 1500px;
    background: #ccc;
    border: 7.5px solid #333;
    box-shadow: 0px 22.5px 45px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.gate-left,
.gate-right {
    position: absolute;
    width: 50%;
    height: 100%;
    background: linear-gradient(135deg, #555, #333);
    transition: transform 1.2s ease;
}

.gate-left {
    left: 0;
    transform-origin: left center;
}

.gate-right {
    right: 0;
    transform-origin: right center;
}

.gate-left.open {
    transform: rotateY(-90deg);
}

.gate-right.open {
    transform: rotateY(90deg);
}

/* Elevator styling */
.elevator-wrapper .shaft {
    position: relative;
    width: 225px;
    height: 375px;
    border: 7.5px solid #333;
    background-color: #ccc;
    border-radius: 15px;
    box-shadow: 0px 7.5px 22.5px rgba(0, 0, 0, 0.2);
}

.elevator-wrapper .elevator {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    background-color: #555;
    border-radius: 7.5px;
    transition: transform 3s ease-in-out;
    box-shadow: 0px 7.5px 15px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 27px;
    font-family: 'Arial', sans-serif;
}

.moving {
    transform: translateY(-100%);
}

/* Barrier gate styling */
.barrier-gate {
    position: relative;
    width: 375px;
    height: 375px;
    background-color: #333;
    border-radius: 15px;
    box-shadow: 0px 7.5px 22.5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

/* Barrier arm */
.barrier-arm {
    width: 15px;
    height: 270px;
    background-color: #555;
    transform-origin: bottom center;
    transform: rotate(0deg);
    transition: transform 1s ease;
    background: linear-gradient(135deg, #fff 50%, #ff0000 50%);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    margin-right: 20px;
}

.barrier-arm.open {
    transform: rotate(-70deg);
}

.section-title {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: #333;
    text-align: center;
}

/* Compact Visitor entry section styling */
.visitor-entry-wrapper {
    margin-top: 10px;
    /* Reduced margin */
    padding: 10px;
    /* Reduced padding */
    border-radius: 8px;
    /* Slightly reduced border-radius */
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Lighter and smaller box-shadow */
    width: 100%;
    max-width: 500px;
    /* Slightly smaller max-width */
    text-align: center;
    font-size: 0.95em;
    /* Slightly smaller font for a compact look */
}

/* Adjusted for smaller font and compact feel */
.visitor-message {
    font-size: 1em;
    /* Standard font size */
    padding: 8px;
    /* Smaller padding */
    color: #333;
    background-color: #55f894;
    /* Light green for valid message */
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

/* Styling for invalid message (alert state) */
.visitor-message.alert {
    background-color: #ffcccb;
    /* Light red for invalid message */
    color: #d32f2f;
}

/* Topic information styling */
.topic-info {
    color: grey;
    margin-top: 5px;
    /* Smaller margin */
    font-size: 0.85em;
    /* Slightly smaller font */
}